import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { useReducer } from 'react';
import { Checkbox, SelectionGroup, Fieldset } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const CheckboxExample = () => {
  const [checkedItems, setCheckedItems] = React.useState({
    'checkbox-checked': true,
    'checkbox-checked-disabled': true
  });
  const onChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.id]: event.target.checked
    });
  };
  return <>
      <Checkbox label="Label" id="checkbox-unchecked" checked={checkedItems['checkbox-unchecked']} onChange={onChange} mdxType="Checkbox" />
      <Checkbox label="Label" id="checkbox-checked" checked={checkedItems['checkbox-checked']} onChange={onChange} mdxType="Checkbox" />
      <Checkbox label="Label" id="checkbox-disabled" checked={checkedItems['checkbox-disabled']} onChange={onChange} disabled mdxType="Checkbox" />
      <Checkbox label="Label" id="checkbox-checked-disabled" checked={checkedItems['checkbox-checked-disabled']} onChange={onChange} disabled mdxType="Checkbox" />
    </>;
};
export const CheckboxDefaultExample = () => {
  const [checkedItems, setCheckedItems] = React.useState({
    'checkbox-checked-2': true,
    'checkbox-checked-disabled-2': true
  });
  const onChange2 = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.id]: event.target.checked
    });
  };
  return <>
      <Checkbox label="Label" id="checkbox-unchecked-2" checked={checkedItems['checkbox-unchecked-2']} onChange={onChange2} mdxType="Checkbox" />
      <Checkbox label="Label" id="checkbox-checked-2" checked={checkedItems['checkbox-checked-2']} onChange={onChange2} mdxType="Checkbox" />
      <Checkbox label="Label" id="checkbox-disabled-2" checked={checkedItems['checkbox-disabled-2']} onChange={onChange2} disabled mdxType="Checkbox" />
      <Checkbox label="Label" id="checkbox-checked-disabled-2" checked={checkedItems['checkbox-checked-disabled-2']} onChange={onChange2} disabled mdxType="Checkbox" />
    </>;
};
export const CheckboxGroupExample = () => {
  const [checkedItems, setCheckedItems] = React.useState({
    "v-checkbox1": true,
    "v-checkbox2": true
  });
  const onChange3 = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };
  return <SelectionGroup label="Checkbox group" mdxType="SelectionGroup">
      <Checkbox id="v-checkbox1" name="v-checkbox1" label="Option" checked={checkedItems["v-checkbox1"]} onChange={onChange3} mdxType="Checkbox" />
      <Checkbox id="v-checkbox2" name="v-checkbox2" label="Option" checked={checkedItems["v-checkbox2"]} onChange={onChange3} mdxType="Checkbox" />
      <Checkbox id="v-checkbox3" name="v-checkbox3" label="Option" checked={checkedItems["v-checkbox3"]} onChange={onChange3} mdxType="Checkbox" />
      <Checkbox id="v-checkbox4" name="v-checkbox4" label="Option" checked={checkedItems["v-checkbox4"]} onChange={onChange3} mdxType="Checkbox" />
    </SelectionGroup>;
};
export const IndeterminateExample = () => {
  const CheckboxState = {
    checked: 0,
    unchecked: 1,
    indeterminate: 2
  };
  const areAllChecked = state => {
    let checkedCount = 0;
    Object.keys(state).forEach(key => {
      if (key === 'controllerCheckbox') {
        return;
      }
      if (state[key] === CheckboxState.checked) {
        checkedCount += 1;
      }
    });
    return checkedCount === 4;
  };
  const areAllUnchecked = state => {
    let checkedCount = 0;
    Object.keys(state).forEach(key => {
      if (key === 'controllerCheckbox') {
        return;
      }
      if (state[key] === CheckboxState.checked) {
        checkedCount += 1;
      }
    });
    return checkedCount === 1;
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'check':
        {
          if (action.payload === 'controllerCheckbox') {
            return {
              controllerCheckbox: CheckboxState.checked,
              checkbox1: CheckboxState.checked,
              checkbox2: CheckboxState.checked,
              checkbox3: CheckboxState.checked,
              checkbox4: CheckboxState.checked,
              checkbox5: CheckboxState.checked
            };
          }
          return {
            ...state,
            [action.payload]: CheckboxState.checked,
            controllerCheckbox: areAllChecked(state) ? CheckboxState.checked : CheckboxState.indeterminate
          };
        }
      case 'uncheck':
        {
          if (action.payload === 'controllerCheckbox') {
            return {
              controllerCheckbox: CheckboxState.unchecked,
              checkbox1: CheckboxState.unchecked,
              checkbox2: CheckboxState.unchecked,
              checkbox3: CheckboxState.unchecked,
              checkbox4: CheckboxState.unchecked,
              checkbox5: CheckboxState.unchecked
            };
          }
          return {
            ...state,
            [action.payload]: CheckboxState.unchecked,
            controllerCheckbox: areAllUnchecked(state) ? CheckboxState.unchecked : CheckboxState.indeterminate
          };
        }
      default:
        throw new Error();
    }
  };
  const initialState = {
    controllerCheckbox: CheckboxState.indeterminate,
    checkbox1: CheckboxState.checked,
    checkbox2: CheckboxState.checked,
    checkbox3: CheckboxState.unchecked,
    checkbox4: CheckboxState.checked,
    checkbox5: CheckboxState.unchecked
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Fieldset heading="Group label *" mdxType="Fieldset">
      <Checkbox aria-controls="checkbox1 checkbox2 checkbox3 checkbox4 checkbox5" id="controllerCheckbox" label="Label" indeterminate={state.controllerCheckbox === CheckboxState.indeterminate} checked={state.controllerCheckbox === CheckboxState.checked} style={{
      marginTop: 'var(--spacing-xs)'
    }} onChange={() => {
      if (state.controllerCheckbox === CheckboxState.unchecked || state.controllerCheckbox === CheckboxState.indeterminate) {
        dispatch({
          type: 'check',
          payload: 'controllerCheckbox'
        });
      } else {
        dispatch({
          type: 'uncheck',
          payload: 'controllerCheckbox'
        });
      }
    }} mdxType="Checkbox" />
      <ul style={{
      marginLeft: 'var(--spacing-s)',
      paddingInlineStart: '0'
    }}>
        {Object.entries(state).map(entry => {
        if (entry[0] === 'controllerCheckbox') {
          return null;
        }
        return <li key={entry[0]} style={{
          marginTop: 'var(--spacing-s)',
          listStyle: 'none'
        }}>
              <Checkbox id={entry[0]} key={entry[0]} label="Label" checked={entry[1] === CheckboxState.checked} onChange={() => {
            if (entry[1] === CheckboxState.unchecked) {
              dispatch({
                type: 'check',
                payload: entry[0]
              });
            } else {
              dispatch({
                type: 'uncheck',
                payload: entry[0]
              });
            }
          }} mdxType="Checkbox" />
            </li>;
      })}
      </ul>
    </Fieldset>;
};
export const _frontmatter = {
  "slug": "/components/checkbox",
  "title": "Checkbox",
  "navTitle": "Checkbox"
};
const layoutProps = {
  CheckboxExample,
  CheckboxDefaultExample,
  CheckboxGroupExample,
  IndeterminateExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <CheckboxExample mdxType="CheckboxExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`If the user can only select one option from a list, use `}<InternalLink href="/components/radio-button" mdxType="InternalLink">{`radio buttons`}</InternalLink>{` instead.`}</li>
      <li parentName="ul">{`Checkbox label should always clearly describe what will happen if the specific option is chosen. A good practice is to keep labels a maximum of three words long.`}</li>
      <li parentName="ul">{`Checkboxes work independently from each other. This means that checking one option should never affect other checkboxes.`}</li>
      <li parentName="ul">{`If Checkboxes are related to each other, use `}<InternalLink href="/components/selection-group" mdxType="InternalLink">{`HDS Selection group`}</InternalLink>{` to achieve that.`}</li>
      <li parentName="ul">{`Checkboxes should not have any immediate effects. The checkbox selection takes effect when the user presses a submit button (e.g. in a form). If you need the selection to have an immediate effect, use `}<InternalLink href="/components/toggle-button" mdxType="InternalLink">{`HDS Toggle button component`}</InternalLink>{` instead. Also see `}<InternalLink href="/foundation/guidelines/checkbox-radiobutton-toggle" mdxType="InternalLink">{`guidelines for choosing between radio buttons, checkboxes and toggles.`}</InternalLink></li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <CheckboxDefaultExample mdxType="CheckboxDefaultExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "checkbox-group",
      "style": {
        "position": "relative"
      }
    }}>{`Checkbox group`}<a parentName="h4" {...{
        "href": "#checkbox-group",
        "aria-label": "checkbox group permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Related checkboxes can be grouped together with the Selection group component. See `}<InternalLink href="components/selection-group" mdxType="InternalLink">{`HDS Selection group page`}</InternalLink>{` for full documentation.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <CheckboxGroupExample mdxType="CheckboxGroupExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "indeterminate",
      "style": {
        "position": "relative"
      }
    }}>{`Indeterminate`}<a parentName="h4" {...{
        "href": "#indeterminate",
        "aria-label": "indeterminate permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Checkboxes also have a "mixed" state that is called an indeterminate state. This state is meant to be between the checked and unchecked states. While not very common, the usual use case for the indeterminate state is nested checkboxes. The parent checkbox can be in the indeterminate state when only part of the children checkboxes are checked.`}</p>
    <p>{`Note! You will always have to use JavaScript to set a checkbox to the indeterminate state. Currently, it cannot be done through HTML. The HDS React component will do this part automatically when the `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` property is given.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <IndeterminateExample mdxType="IndeterminateExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      